import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EventPopup from '../components/EventPopup';
import Seo from '../components/Seo';
import {handleAnalytics} from '../shared/analytics';
import {TRACK_NAVIGATION_DAY} from '../shared/constants';
import SwipeableDate from '../components/SwipeableDate';

class DayPage extends React.Component {
  static propTypes = {
    viewport: PropTypes.object.isRequired
  };

  componentDidMount() {
    handleAnalytics({event: TRACK_NAVIGATION_DAY});
  }

  render() {
    const {viewport} = this.props;

    return (
      <Layout>
        <Seo />
        {viewport.isMobile && <Header interval="week" />}
        {!viewport.isMobile && <Header interval="day" />}
        <SwipeableDate type="day" />
        <Footer />
        <EventPopup />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewport: state.viewport
  };
};

export default connect(mapStateToProps)(DayPage);
